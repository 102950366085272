<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="table-title">
        <strong>{{ messages.tables.results }} </strong><slot name="area"></slot> <slot name="month"></slot> / 
        <slot name="fiscalYear"></slot>
      </span>
<!--      <ExportExcelButton-->
<!--        :tableId="tableId"-->
<!--        @export-table="exportTableToExcel($event)"-->
<!--      />-->
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :ref="tableId"
        :items="tableData"
        :fields="fields"
        hover
        outlined
        border
        clickableRows
        :columnFilter="false"
        :tableFilter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import TableDataToExcel from "@/helpers/TableDataToExcel";
const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
  );
export default {
  name: "TablePurchasesTracking",
  data() {
    return {
      styledItems: null,
      itemsUpperCased: null,
      user: null,
    };
  },
  components: {
    ExportExcelButton,
  },
  computed: {
    items() {
      console.log("computedItems");
      console.log(this.tableData);
      return this.tableData;
    },
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    fields() {
      return [
        {
          key: "concept",
          label: this.messages.purchasesTracking.concept.toUpperCase(),
        },
        {
          key: "targetPurchases",
          label: this.messages.purchasesTracking.targetPurchases.toUpperCase(),
        },
        {
          key: "purchases",
          label: `${this.messages.purchasesTracking.purchases.toUpperCase()} €`,
        },
        {
          key: "purchasesCompletion",
          label:
            this.messages.purchasesTracking.purchasesCompletion.toUpperCase(),
        },
        {
          key: "purchasesComparative",
          label:
            this.messages.purchasesTracking.purchasesComparative.toUpperCase(),
        },
        {
          key: "purchasesUioAverage",
          label: `€/${this.messages.purchasesTracking.purchasesUioAverage}`,
        },
        {
          key: "purchasesUioAverageComparative",
          label: `€/${this.messages.purchasesTracking.purchasesUioAverageComparative.toUpperCase()}`,
        },
        {
          key: "nationalPurchasesAverage",
          label: `€ / ${this.messages.purchasesTracking.nationalPurchasesAverage.toUpperCase()}`,
        },
        {
          key: "nationalPurchasesAverageComparative",
          label: `€/${this.messages.purchasesTracking.nationalPurchasesAverageComparative.toUpperCase()}`,
        },
        {
          key: "uniPar",
          label: `% ${this.messages.purchasesTracking.uniPar.toUpperCase()}`,
        },
        {
          key: "nationalUniPar",
          label: `% ${this.messages.purchasesTracking.nationalUniPar.toUpperCase()}`,
        },
        {
          key: "purchasesQuantity",
          label:
            this.messages.purchasesTracking.purchasesQuantity.toUpperCase(),
        },
        { key: "ncs", label: `€/${this.messages.purchasesTracking.ncs}` },
      ];
    },
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    tableId: {
      type: String,
      required: false,
    },
  },
  methods: {
    exportTableToExcel(tableId) {
      TableDataToExcel(
        this.$refs[tableId]._props.fields,
        this.$refs[tableId]._props.items,
        this.tableId
      );
    },
  },
};
</script>
