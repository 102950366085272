var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"flex flex--space-between flex--align-items-baseline"},[_c('span',{staticClass:"table-title"},[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._t("area"),_vm._v(" "),_vm._t("month"),_vm._v(" / "),_vm._t("fiscalYear")],2)]),_c('CCardBody',[_c('CDataTable',{ref:_vm.tableId,attrs:{"items":_vm.tableData,"fields":_vm.fields,"hover":"","outlined":"","border":"","clickableRows":"","columnFilter":false,"tableFilter":{
          label: _vm.messages.tables.filter,
          placeholder: `${_vm.messages.tables.search} ...`,
        },"cleaner":"","noItemsView":{
          noItems: _vm.messages.tables.noItems,
          noResults: _vm.messages.tables.noResults,
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }